<template>
  <v-container
    fluid
    class="loading-container height-100 d-flex justify-center align-center"
  >
    <v-overlay v-model="loading" absolute >
      <v-progress-circular
        color="#0F3F67"
        size="258"
        width="6"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-img
      aspect-ratio="1"
      width="250"
      max-width="250"
      :src="require(`@/assets/logos/r-logo-white.png`)"
      contain
    >
    </v-img>
  </v-container>
</template>

<script>
import * as disp from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { Env } from "../environment/EnvironmentFactory";


// @ is an alias to /src
export default {
  name: "Loading",
  data: () => ({
    loading: true,
  }),
  mounted() {
    Env();
  },
  methods: {
    navTo(href) {
      this.$router.push({ path: href });
    },
  },
  computed: {
    ...mapFields("envStyle", ["colors", "images", "error"]),
    loaded() {
      if (!disp.IsNullorWhitespace(this.error)) {
        return false;
      }
      if (
        Object.keys(this.colors).length > 0 ||
        Object.keys(this.images).length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    loaded: {
      handler() {
        if (this.loaded) {
          this.navTo("/Login");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.loading-container {
  background-color: #f4f4f4;
}
</style>
